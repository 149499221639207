<template>
  <b-container fluid>
    <b-row class="heading_alignment">
      <b-col md="12">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4><br>
          </template>
          <template>
            <div class="row p-0">
              <!-- Available Affiliate Codes -->
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="card text-white bg-primary iq-mb-3">
                  <div class="card-body" style="text-align:center">
                    <div class="mr-2">
                      Available<br>Codes
                    </div>
                    <h4 style="color:#fff">{{affCodeAnalyticsObj.available_code_count ? affCodeAnalyticsObj.available_code_count : 0}}</h4>
                  </div>
                </div>
              </div><!-- Available Affiliate Codes -->

              <!-- Used Affiliate Codes -->
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="card iq-mb-3 text-white" style="background-color:var(--cyan)">
                  <div class="card-body" style="text-align:center">
                    <div class="mr-2">
                      Used<br>Codes
                    </div>
                    <h4 style="color:#fff">{{affCodeAnalyticsObj.allocated_code_count ? affCodeAnalyticsObj.allocated_code_count : 0}}</h4>
                  </div>
                </div>
              </div><!-- Used Affiliate Codes -->

              <!-- Students Registered -->
              <div class="col-12 col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="card iq-mb-3 text-white bg-danger">
                  <div class="card-body" style="text-align:center">
                    <div class="mr-2">
                      Students<br>Registered
                    </div>
                    <h4 style="color:#fff">{{affCodeAnalyticsObj.registered_student_count ? affCodeAnalyticsObj.registered_student_count : 0}}</h4>
                  </div>
                </div>
              </div><!-- Students Registered -->
            </div>
            <div class="dashboardBtn">
              <b-btn variant="primary mr-2" class="Download_button" @click="inviteOrgSaasUser()">Invite User</b-btn>
              <b-btn variant="primary mr-2" class="Download_button" @click="navigateTo('/gpath_session_list')">GPaths Dashboard</b-btn>
              <!-- <b-btn variant="primary mr-2" class="Download_button" @click="navigateTo('/testseries_dashboard')">TestSeries Dashboard</b-btn> -->
              <b-btn variant="primary mr-2" class="Download_button" @click="navigateTo(`/affiliate_codes_dashboard?module_name=${GideProducts.GPATHS}`)">Manage Codes</b-btn>
              <b-btn v-if="userIsOrgAdmin()" variant="primary mr-2" class="Download_button" @click="navigateTo(`/org_edit/ORG/${saasOrgData && saasOrgData.org_id ? saasOrgData.org_id : orgData.org_id}`)">Manage Website</b-btn>
              <b-btn v-if="userIsOrgAdmin()" variant="primary mr-2" class="Download_button" @click="navigateTo(`/manage_users/ORG/${saasOrgData && saasOrgData.org_id ? saasOrgData.org_id : orgData.org_id}`)">Manage Teams</b-btn>
              <b-btn v-if="userIsOrgAdmin()" variant="primary mr-2" class="Download_button" @click="navigateTo(`/my_billing_history/${userData.user_id}`)">Manage Billing</b-btn>
              <b-btn v-if="VUE_APP_ENV != 'prod'" variant="primary mr-2" class="Download_button" @click="navigateTo('/config_menu_list/Resources')">Resources</b-btn>
              <b-btn v-if="userIsOrgAdmin()" variant="primary mr-2" class="Download_button" @click="navigateTo('/subscription_plans')">Buy More Codes</b-btn>
            </div>
          </template>
          <template>
            <b-row class="w-100 mb-3">
              <b-col class="col-12 col-sm-4 col-md-3 col-lg-3 mt-4">
              <!-- Frontend Search -->
                <!-- <b-form-input class="search_box"
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input> -->
              <!-- Frontend Search -->

              <!-- Backend Search -->
              <input class="form-control"
                  id="search"
                  v-model="whereFilter.search_param"
                  type="search"
                  @input="searchUserWithParams"
                  :placeholder="cvSearchText" />
              <!-- Backend Search -->
              </b-col>
              <b-col class="w-100 col-12 col-sm-12 col-md-12 col-lg-12 mb-2">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100" v-model="whereFilter.dateRange" @update="orgSaasDashboardListDateWise()"></date-range-picker>
                </div>
              </b-col>

              <b-col class="col-12 col-sm-12 col-md-3 col-lg-2 mb-2">
                <!-- Frontend csv download -->
                <!-- <VueJsonToCsv :json-data="orgSaasDashboardObjList" csv-title="userDocument" v-if="orgSaasDashboardObjList && orgSaasDashboardObjList.length">
                  <b-btn variant="primary" class="Download_button"> CSV
                  </b-btn>
                </VueJsonToCsv>Frontend csv download -->

                <!-- Backend csv download -->
                <b-btn variant="primary" class="Download_button" @click="backendCsvDownload"> CSV</b-btn>
                <!-- Backend csv download -->
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="orgSaasDashboardObjList && orgSaasDashboardObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="orgSaasDashboardObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage">
                  <template v-slot:cell(user_name)="data">
                    <span class="pointer" @click="openUserProfile(data.item.user_id)">{{ data.item.user_name }}</span><br>
                    <span>
                      <small>
                        ID:&nbsp;{{data.item.user_id}}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_id)"></i><br>
                        Email:&nbsp;{{data.item.user_email}}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Email" @click="doCopy(data.item.user_email)"></i><br><br>
                        Mobile:&nbsp;{{data.item.user_mobile}}<br>
                        Class:&nbsp;{{data.item.user_class}}<br>
                        Board:&nbsp;{{data.item.user_board}}<br>
                        School:&nbsp;{{data.item.user_school}}<br>
                        Country:&nbsp;{{data.item.user_country}}<br>
                      </small>
                    </span>
                  </template>
                  <template v-slot:cell(assigned_code)="data">
                    <div  v-for="(code, index) of data.item.assigned_code.split(',')" :key="index" >
                      <b-badge :style="getStateColorCode()" class="mb-2">
                        {{code}}
                      </b-badge>
                    </div>
                  </template>
                  <template v-slot:cell(product)="data">
                    <div class="pointer" v-for="(pdt, index) of userSubsPdtMap[data.item.user_id].split(',')" :key="index" @click="goToUserSubscriptions(data.item.user_id)">
                      <b-badge v-if="pdt.length > 0" :style="getStateColorCode()" class="mb-2">
                        {{pdt}}
                      </b-badge><br>
                    </div>
                  </template>
                  <template v-slot:cell(allocator_name)="data">
                    <span class="pointer">
                      <small @click="openUserProfile(data.item.allocator_user_id)">Team Member:&nbsp;{{data.item.allocator_name}}</small><br/>
                      <small>Email:&nbsp;{{data.item.allocator_email}}</small>&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy allocator email" @click="doCopy(data.item.allocator_email)"></i><br>
                      <small>ID:&nbsp;{{data.item.allocator_user_id}}</small>&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy allocator email" @click="doCopy(data.item.allocator_email)"></i><br>
                    </span>
                  </template>

                  <!-- Registration Status -->
                  <template v-slot:cell(user_is_profile_complete)="data">
                    <b-badge v-if="data.item.user_is_profile_complete == 1" :style="getStateColorCode(data.item.user_is_profile_complete)" class="mb-2">
                      Registered
                    </b-badge>
                    <b-badge v-else :style="getStateColorCode(data.item.user_is_profile_complete)" class="mb-2">
                      Invited
                    </b-badge>
                  </template><!-- Registration Status -->
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                <!-- No Data Found -->
                Kindly change your calendar dates or <span class="primary-color pointer" @click="navigateTo('/subscription_plans')">Click here</span> to view the available Subscription Plans. Purchase a suitable plan and avail codes. Share the codes with your students to onboard them on GIDE.AI
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style scoped>
.dateRange {
  float: right;
  }
  .iq-card .iq-card-header{
    padding: 0px!important;
  }
  .user-card{
    font-size: 30px !important;
  }
  .textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
<script>
import { socialvue } from "../../../config/pluginInit.js"
import { OrgSaas } from "../../../FackApi/api/orgSaas.js"
import { Organisations } from "../../../FackApi/api/organisation"
import GideProducts from "../../../FackApi/json/GideProducts.json"

export default {
  name: "OrgSaas",
  components: {
  },
  data () {
    return {
      GideProducts: GideProducts,
      cvCardTitle: "",
      cvSearchText: "Type to Search",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Organisation Dashboard",
      sortBy: "user_name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "User Details ", key: "user_name", class: "text-left align-text-top", sortable: true },
        { label: "Assigned Codes ", key: "assigned_code", class: "text-left align-text-top", sortable: true },
        { label: "Assigned By ", key: "allocator_name", class: "text-left align-text-top", sortable: true },
        { label: "Product Subscription ", key: "product", class: "text-left align-text-top", sortable: true },
        { label: "Registration Status ", key: "user_is_profile_complete", class: "text-left align-text-top", sortable: true }
      ],
      orgSaasDashboardObjList: [],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 7)), // in Last 7 Days
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        search_param: ""
      },
      currentBatchNo: 1,
      dataPerPage: 100,
      showLoadMoreBtn: true,
      affCodeAnalyticsObj: {},
      userSubsPdtMap: {},
      orgData: {},
      orgId: null,
      VUE_APP_ENV: process.env.VUE_APP_ENV
    }
  },
  async mounted () {
    socialvue.index()
    await this.getFiltersFromCache()

    if (this.$route.query.org_id) {
      if ((this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118")) {
        this.orgId = this.$route.query.org_id
        await this.getOrgDetails()
      }
    }
    else if (!this.saasOrgData || this.saasOrgData.org_addmin_email != this.userData.user_email) {
      // orgadmin visits GIDE from the platform's login page4
      const otherOrgId = Object.keys(this.userData.modules.organisations).find(e => e != "GIDE_ORG_1")
      if (otherOrgId && this.userData && this.userData.roles[otherOrgId].udr_name) {
        this.orgId = otherOrgId
        await this.getOrgDetails()
      }
    }
    else {
      // orgadmin visits GIDE from his/her own login page
      this.orgId = this.saasOrgData.org_id
    }

    this.cvCardTitle = this.saasOrgData && this.saasOrgData.org_name ? this.saasOrgData.org_name + " Dashboard" : this.orgData.org_name
    this.getAffCodeAnalytics()
    this.getOrgUserData()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    },
    saasOrgData () {
      return this.$store.getters["SaasOrgData/getSaasOrgData"]
    }
  },
  methods: {
    /**
     * openUserProfile
     */
    openUserProfile (userId) {
      let url = `/user/${userId}`
      window.open(url, "_blank")
    },
    /**
     * userIsOrgAdmin
     */
    userIsOrgAdmin () {
      if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
        // Display all the buttons to GIDE_ADMIN and GIDE_COUNSELLORS
        return true
      }

      if (this.userData && this.orgId && this.userData.roles[this.orgId] && this.userData.roles[this.orgId].udr_name && (this.userData.roles[this.orgId].udr_name.toLowerCase() === "admin" || this.userData.roles[this.orgId].udr_name.toLowerCase() === "owner")) {
        return true
      }
      return false
    },
    /**
     * navigateTo
     */
    navigateTo (url = null) {
      if (url) {
        this.$router.push(url)
        // window.open(url, "_blank")
      }
    },
    /**
     * inviteOrgSaasUser
     */
    inviteOrgSaasUser () {
      this.$router.push(`/gpath_session_list?invite_model=${true}`)
    },
    /**
     * calling user_analytics and userlist function on date wise and storing value in store
    */
    async orgSaasDashboardListDateWise () {
      await this.setDateFilter()
      this.getOrgUserData()
    },
    /**
     * getOrgUserData
     */
    async getOrgUserData (loadViaLoadMore = false, downloadCsv = false) {
      try {
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.downloadCsv = downloadCsv

        const orgSaasDashboardObj = {
          aff_entity_id: this.orgId,
          filter: this.whereFilter
        }

        const orgSaasStudentDashboardList = await OrgSaas.orgSaasStudentDashboardList(this, orgSaasDashboardObj)
        if (!orgSaasStudentDashboardList.resp_status) {
          if (this.currentBatchNo > 1) {
            // anything less than 1 (which is the first page) becomes an invalid prop for the b-pagination component
            this.currentBatchNo--
          }
          this.showLoadMoreBtn = false
          return
        }

        if (downloadCsv) {
          window.open(orgSaasStudentDashboardList.resp_csv_file_url)
          return
        }

        if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
          this.orgSaasDashboardObjList = [ ...orgSaasStudentDashboardList.resp_data.data.orgsaas_student_dashboard_list ]
        }
        else {
          this.orgSaasDashboardObjList = [ ...this.orgSaasDashboardObjList, ...orgSaasStudentDashboardList.resp_data.data.orgsaas_student_dashboard_list ]
        }

        this.userSubsPdtMap = orgSaasStudentDashboardList.resp_data.data.user_product_map
      }
      catch (err) {
        console.error("Exception in getOrgUserData and err: ", err)
      }
    },
    /**
     * goToUserSubscriptions
     */
    goToUserSubscriptions (userid) {
      window.open(`my_billing_history/${userid}`)
    },
    /**
     * getAffCodeAnalytics
     */
    async getAffCodeAnalytics () {
      try {
        const affCodeAnalyticsGetObj = {}

        if (this.saasOrgData && this.saasOrgData.org_id) {
          affCodeAnalyticsGetObj.aff_entity_id = this.saasOrgData.org_id
        }
        else if (this.orgData && this.orgData.org_id) {
          affCodeAnalyticsGetObj.aff_entity_id = this.orgData.org_id
        }
        else {
          this.toastMsg = "Organisation id not found"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }

        let affCodeAnalytics = await OrgSaas.orgSaasAffCodeAnalyticsGet(this, affCodeAnalyticsGetObj)
        if (!affCodeAnalytics.resp_status) {
          this.toastMsg = affCodeAnalytics.resp_msg
          this.toastVariant = "danger"
          this.showToast = false
          return
        }

        this.affCodeAnalyticsObj = affCodeAnalytics.resp_data.data
      }
      catch (err) {
        console.error("Exception in getAffCodeAnalytics and err: ", err)
      }
    },
    /**
     * getStateColorCode
     */
    getStateColorCode (registrationState = null) {
      if (registrationState && registrationState != 1) {
        return `vertical-align: middle; color: #FFF; background: #FF9900 !important;`
      }
      else {
        return `vertical-align: middle; color: #FFF; background: #e5252c !important;`
      }
    },
    /**
     * get data from store cache by this method
    */
    async getFiltersFromCache () {
      await this.getDateFilter()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.getOrgUserData(true)
    },
    /**
     * getOrgDetails
     */
    async getOrgDetails () {
      try {
        const orgDomain = window.location.hostname
        const orgDetails = await Organisations.organisationView(this, this.orgId, orgDomain)
        if (!orgDetails.resp_status) {
          return
        }
        this.orgData = orgDetails.resp_data.data

        // set the org data in local storage for saving in user table
        this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
      }
      catch (err) {
        console.error("Exception in getOrgDetails and err: ", err)
      }
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter.dateRange = { ...storeDate.dateRange }
      }
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     *
     * Redirect to User Profile
     */
    goToUserProfile (user) {
      try {
        window.open(`/user/${user.user_id}`)
      }
      catch (err) {
        console.error("Exception occurred at UserList() and Exception:", err.message)
      }
    },
    /**
     * searchUserWithParams
     */
    async searchUserWithParams () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }
      this.showLoadMoreBtn = false
      this.currentBatchNo = 1
      await this.getOrgUserData()
    },
    /**
     * showPaymentList
     */
    showPaymentList (user) {
      window.open(`/payTransactions_list/${user.user_id}`)
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * backendCsvDownload
     */
    async backendCsvDownload () {
      this.getOrgUserData(false, true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .dashboardBtn {
    display: flex;
    flex-wrap: wrap;

    button {
      margin-bottom: 10px;
    }
  }
</style>
